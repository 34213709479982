export const LANDING = '/';
export const SIGN_UP = '/signup/';
export const SIGN_IN = '/signin/';
export const HOME = '/home/';
export const MOBILE_VERFICATION = '/mobileVerification';
export const KNOWLEDGE_BASE = '/knowledge-base/';
export const ACCOUNT = '/account/';
export const FORGOT_PASSWORD = '/forgotPasswordEmail';
export const ADMIN = '/admin/';
export const ADMIN_DETAILS = '/admin/:id';
export const FAQ = '/faq/';
export const ABOUT_US = '/about/';
export const PRIVACY_POLICY = '/privacy-policy/';
export const CHAT_DEMO = '/chatDemo/';
export const BLOG = '/blog/';
export const SURVEY = '/survey/';
export const MERCHANT = '/merchant/';