/** @jsx jsx */
import {jsx} from 'theme-ui';
import React from 'react';
import {Helmet} from 'react-helmet';
import {graphql} from 'gatsby';
import {MDXRenderer} from 'gatsby-plugin-mdx';
import {mediaQueries} from '../gatsby-plugin-theme-ui';

import Layout from '../components/layout';
import {itemListKnowledgeBase} from '../utils/sidebar/item-list';

import TableOfContents from '../components/knowledgeBase-table-of-contents';
import FooterLinks from '../components/footer-links';
import Breadcrumb from '../components/knowledgeBase-breadcrumb';
import Container from '../components/container';
import PrevAndNext from '../components/prev-and-next';

const containerStyles = {
	maxWidth: (t) =>
		`calc(${t.sizes.mainContentWidth.withSidebar} + ${t.sizes.tocWidth} + ${t.space[9]} + ${
			t.space[9]
		} + ${t.space[9]})`,
	px: 9,
};

const getKnowledgeBaseData = (location) => {
	const [urlSegment] = location.pathname.split(`/`).slice(1);
	const itemListLookup = {
		'knowledge-base': itemListKnowledgeBase,
	};

	return [urlSegment, itemListLookup[urlSegment]];
};

function KnowledgeBaseTemplate({data, location, pageContext: {next, prev}}) {
	const page = data.mdx;
	const [urlSegment, itemList] = getKnowledgeBaseData(location);
	const toc = !page.frontmatter.disableTableOfContents && page.tableOfContents.items;

	return (
		<React.Fragment>
			<Helmet>
				<title>{page.frontmatter.title}</title>
				<meta name="description" content={page.excerpt} />
				<meta property="og:description" content={page.excerpt} />
				<meta property="og:title" content={page.frontmatter.title} />
				<meta property="og:type" content="article" />
				<meta name="twitter:description" content={page.excerpt} />
				<meta name="twitter.label1" content="Reading time" />
				<meta name="twitter:data1" content={`${page.timeToRead} min read`} />
			</Helmet>
			<Layout
				location={location}
				itemList={itemList}
				enableScrollSync={urlSegment === `knowledge-base` ? false : true}
			>
				<Container
					overrideCSS={{
						pb: 0,
						[mediaQueries.lg]: {
							pt: 9,
						},
						[toc && mediaQueries.xl]: {
							...containerStyles,
						},
					}}
				>
					<Breadcrumb location={location} itemList={itemList} />
					<h1 id={page.fields.anchor} sx={{mt: 0}}>
						{page.frontmatter.title}
					</h1>
				</Container>
				<Container
					overrideCSS={{
						pt: 0,
						position: `static`,
						[mediaQueries.lg]: {
							pb: 9,
						},
						[toc && mediaQueries.xl]: {
							...containerStyles,
							display: `flex`,
							alignItems: `flex-start`,
						},
					}}
				>
					{toc && (
						<div
							sx={{
								order: 2,
								[mediaQueries.xl]: {
									ml: 9,
									maxWidth: `tocWidth`,
									position: `sticky`,
									top: (t) =>
										`calc(${t.sizes.headerHeight} + ${t.sizes.bannerHeight} + ${
											t.space[9]
										})`,
									maxHeight: (t) =>
										`calc(100vh - ${t.sizes.headerHeight} - ${
											t.sizes.bannerHeight
										} - ${t.space[9]} - ${t.space[9]})`,
									overflow: `auto`,
								},
							}}
						>
							<TableOfContents location={location} page={page} />
						</div>
					)}
					<div
						sx={{
							[page.tableOfContents.items && mediaQueries.xl]: {
								maxWidth: `mainContentWidth.withSidebar`,
								minWidth: 0,
							},
						}}
					>
						<div>
							<MDXRenderer slug={page.fields.slug}>{page.body}</MDXRenderer>
							<PrevAndNext sx={{mt: 9}} prev={prev} next={next} />
						</div>
					</div>
				</Container>
				<FooterLinks />
			</Layout>
		</React.Fragment>
	);
}

export default KnowledgeBaseTemplate;

export const pageQuery = graphql`
	query($path: String!) {
		mdx(fields: {slug: {eq: $path}}) {
			body
			excerpt
			timeToRead
			tableOfContents
			fields {
				slug
				anchor
			}
			frontmatter {
				title
				disableTableOfContents
				tableOfContentsDepth
			}
		}
	}
`;
