/** @jsx jsx */
import { jsx } from "theme-ui";
import React from "react";
import { Helmet } from "react-helmet";
import { navigate, PageRenderer } from "gatsby";
import mousetrap from "mousetrap";
import Modal from "react-modal";
import { MdClose } from "react-icons/md";
import { colors, space, zIndices } from "../gatsby-plugin-theme-ui";
// import { breakpointGutter } from "../utils/styles";

import Navigation from "../components/navigation";
import PageWithSidebar from "../components/page-with-sidebar";
import SiteMetadata from "../components/site-metadata";

import "../assets/fonts/futura";

let windowWidth;

class DefaultLayout extends React.Component {
  constructor() {
    super();
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  handleCloseModal() {
    navigate(this.props.modalBackgroundPath);
  }

  componentDidMount() {
    if (this.props.isModal && window.innerWidth > 750) {
      mousetrap.bind(`left`, this.props.modalPrevious);
      mousetrap.bind(`right`, this.props.modalNext);
      mousetrap.bind(`spacebar`, this.props.modalNext);

      document.querySelector(`html`).style.overflowY = `hidden`;
    }
  }

  componentWillUnmount() {
    if (this.props.isModal && window.innerWidth > 750) {
      mousetrap.unbind(`left`);
      mousetrap.unbind(`right`);
      mousetrap.unbind(`spacebar`);

      document.querySelector(`html`).style.overflowY = `auto`;
    }
  }

  render() {
    const isSidebarDisabled =
      this.props.isSidebarDisabled || !this.props.itemList;
    let isModal = false;
    if (!windowWidth && typeof window !== `undefined`) {
      windowWidth = window.innerWidth;
    }
    if (this.props.isModal && windowWidth > 750) {
      isModal = true;
    }
    const isDark = false;

    if (isModal && window.innerWidth > 750) {
      return (
        <React.Fragment>
          <PageRenderer
            location={{ pathname: this.props.modalBackgroundPath }}
          />
          <Modal
            isOpen={true}
            style={{
              content: {
                background: `none`,
                border: `none`,
                bottom: `inherit`,
                left: `inherit`,
                margin: `0 auto`,
                overflow: `visible`,
                padding: `${space[8]} 0`,
                right: `inherit`,
                top: `inherit`,
                width: `750px`
              },
              overlay: {
                backgroundColor: colors.modal.overlayBackground,
                bottom: `unset`,
                left: 0,
                minHeight: `100%`,
                minWidth: `100%`,
                overflowY: `auto`,
                position: `absolute`,
                right: 0,
                top: 0,
                zIndex: zIndices.modal
              }
            }}
            onRequestClose={() => navigate(this.props.modalBackgroundPath)}
            contentLabel="Site Details Modal"
          >
            <div
              sx={{
                bg: `card.background`,
                borderRadius: 2,
                boxShadow: `dialog`,
                position: `relative`
              }}
            >
              <button
                onClick={this.handleCloseModal}
                sx={{
                  bg: `card.background`,
                  border: 0,
                  borderRadius: 6,
                  color: `textMuted`,
                  cursor: `pointer`,
                  fontSize: 4,
                  height: 40,
                  left: `auto`,
                  position: `absolute`,
                  right: t => t.space[7],
                  top: t => t.space[8],
                  width: 40,
                  "&:hover": {
                    bg: `ui.hover`,
                    color: `gatsby`
                  }
                }}
              >
                <MdClose />
              </button>
              {this.props.children}
              {this.props.modalPreviousLink}
              {this.props.modalNextLink}
            </div>
          </Modal>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Helmet
          title="AutoSave"
          meta={[
            {
              name: "description",
              content: "Invest in FDs"
            },
            {
              name: "keywords",
              content: "autosave, finance, gatsby, blog, pwa"
            },
            {
              name: "viewport",
              content: "width=device-width, initial-scale=1.0"
            }
          ]}
        >
          <html lang="en" />
        </Helmet>
        <SiteMetadata pathname={this.props.location.pathname} />
        <Navigation pathname={this.props.location.pathname} />
        <div
          className={`main-body docSearch-content`}
          sx={{
            px: `env(safe-area-inset-left)`,
            pt: t => t.sizes.bannerHeight,
            // make room for the mobile navigation
            pb: t => t.sizes.headerHeight,
          }}
        >
          <PageWithSidebar
            disable={isSidebarDisabled}
            itemList={this.props.itemList}
            location={this.props.location}
            enableScrollSync={this.props.enableScrollSync}
            renderContent={() => this.props.children}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default DefaultLayout;
